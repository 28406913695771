const cookieHelper = {
    getRegionId: function() {
        const cookies = document.cookie.split('; ');
        for (let cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (name === 'regionId') {
                let regionId = decodeURIComponent(value);
                if (regionId === 'krr') {
                    regionId = 'krasnodar';
                }
                return regionId;
            }
        }
        return null; // Возвращаем null, если regionId не найден
    },

    setRegionId: function(regionId, days = 365) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        if (regionId === 'krr') {
            regionId = 'krasnodar';
        }
        document.cookie = `regionId=${encodeURIComponent(regionId)}; ${expires}; path=/`;
    },

    hasRegionId: function() {
        const cookies = document.cookie.split('; ');
        for (let cookie of cookies) {
            const [name] = cookie.split('=');
            if (name === 'regionId') {
                return true;
            }
        }
        return false;
    }
};

// Экспортируем объект cookieHelper
export default cookieHelper;