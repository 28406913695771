import {acceptHMRUpdate, defineStore} from "pinia";
import {useClinics} from "~/store/clinics";
import {useDocs} from "~/store/docs";
import cookieHelper from "~/libs/region";

export const useRegion = defineStore("region", {
    state: () => {

        return {
            regionId: null,
        };
    },
    getters: {
        listClinicsRegion: (state) => (regionId = false) => {
            const clinicStore = useClinics();
            const listClinics = [];
            regionId = regionId || state.regionId;

            clinicStore.listClinics.forEach((item) => {
                if (item.attributes.region === regionId) {
                    listClinics.push(item.attributes.clinicId);
                }
            })

            return listClinics;
        },
        listDoctorsRegion: (state) => (regionId = false) => {
            const docStore = useDocs();
            const listDoctors = [];

            docStore.listDocs.forEach((item) => {
                if (item.attributes.hideInApp) {
                    return;
                }

                if (item.attributes.DocId.length > 0) {
                    if (item.attributes.DocId.find((docId) => state.listClinicsRegion().includes(docId.clinic.data.attributes.clinicId)) !== undefined) {
                        listDoctors.push(item.id);
                    }
                }

                // if (item.attributes.clinics.data.find((clinic) => clinic.attributes.region === regionId) !== undefined) {
                //     listDoctors.push(item.id);
                // }
            });

            return listDoctors;
        }
    },
    actions: {
        setRegion() {
            if (this.regionId === null) {
                this.regionId = cookieHelper.getRegionId();
            }
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRegion, import.meta.hot));
}
