import {acceptHMRUpdate, defineStore} from "pinia";
import {useClinics} from "~/store/clinics";
import {useApi} from "~/store/app/api";
import qs from 'qs';
import {useRegion} from "~/store/app/region";

export const useSpecials = defineStore("specials", {
    state: () => {
        const config = useRuntimeConfig();

        return {
            apiBase: config.public.apiBase,
            cache: config.public.cache,
            listSpecials: [],
            textSearch: "",
        };
    },

    getters: {
        getSpecialById: (state) => (specialId) =>
            state.listSpecials.find((item) => item.id === specialId),
        getSpecialByLink: (state) => (link) =>
            state.listSpecials.find((item) => item.attributes.link === link),
        filterListSpecials: (state) => {
            const regionStore = useRegion();
            const hasDocs = (special) => special?.attributes?.docs?.data?.length > 0
            const hasMatchingDoctor = (special) => special?.attributes?.docs?.data?.findIndex((doc) => {
                return regionStore.listDoctorsRegion().findIndex((doctor) => doctor === doc.id) !== -1;
            }) !== -1;

            return state.listSpecials.filter((special) => {
                if (special?.attributes?.title === 'Все специализации') return true;
                return hasDocs(special) && hasMatchingDoctor(special);
            });
        },
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
        },
    },

    actions: {
        async fetchSpecials2() {
            const apiStore = useApi();
            let populate = {
                fields: ['id', 'title'],
                docs: {
                    fields: ['id']
                },
                prices: {
                    fields: ['id']
                },
            };

            const query = qs.stringify({populate, pagination: {pageSize: 1000},}, {encode: true});
            const url = `/api/specials?${query}`;
            console.log(url)
            this.listSpecials = await apiStore.fetchData(url);
        },
        async fetchSpecials() {
            const startTime = performance.now();
            const url = `/api/specials?pagination[pageSize]=1000&populate=docs,prices&sort=title`;
            let listSpecials = await fetch(
                this.createApiUrl(
                    url
                )
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));
            if (listSpecials?.length) {
                function compare(a, b) {
                    if (a.attributes.title < b.attributes.title) {
                        return -1;
                    }
                    if (a.attributes.title > b.attributes.title) {
                        return 1;
                    }
                    return 0;
                }

                this.listSpecials = [
                    {id: null, attributes: {title: "Все специализации"}},
                    ...listSpecials,
                ];

                // this.listSpecials = [...listSpecials];
            } else {
                console.log(`List specials is empty ${url}`);
            }
            const endTime = performance.now();
            const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);
            console.log(`${durationInSeconds} - ${url}`);
        },
        async fetchSpecial(specialId) {
            const startTime = performance.now();
            const url = `/api/specials/${specialId}?populate=*`;
            const special = await fetch(
                this.createApiUrl(
                    url
                )
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));
            if (Object.values(special)?.length) {
                const endTime = performance.now();
                const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);
                console.log(`${durationInSeconds} - ${url}`);
                return special;
            } else {
                console.log(`List specials is empty ${url}`);
            }
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSpecials, import.meta.hot));
}
