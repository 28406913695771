import {acceptHMRUpdate, defineStore} from "pinia";
import qs from 'qs';

export const useApi = defineStore("api", {
    state: () => {
        const config = useRuntimeConfig();

        return {
            apiBase: config.public.apiBase,
            cache: config.public.cache,
            debug: config.public.STAGE === 'local',
        }
    },

    getters: {
        createApiUrl: (state) => (url) => {
            const cacheParam = state.cache ? "" : `&${new Date().getTime()}`;
            return `${state.apiBase}${url}${cacheParam}`;
        },
    },

    actions: {
        async fetchData(url) {
            const startTime = performance.now();
            try {
                const response = await fetch(this.createApiUrl(url));
                const result = await response.json();
                const data = result.data;

                const endTime = performance.now();
                const durationInSeconds = ((endTime - startTime) / 1000).toFixed(3);

                if (this.debug) {
                    console.log(`${durationInSeconds} - ${url}`);
                }

                if (data?.length) {
                    return data;
                } else {
                    console.error("Data is empty");
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useApi, import.meta.hot));
}
